import "./style.css"
import SwiperMain from "./../Swiper/Swiper"

const Slider = () => {

    return (
        <div className="slider">
            <SwiperMain />
            <div className="slider__nav">
                <div className="slider__nav-prev"></div>
                <a href="mailto:"><div className="slider__nav-advbtn">принять участие</div></a>
                <div className="slider__nav-next"></div>
            </div>
        </div>
    );
}

export default Slider;
