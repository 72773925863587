const aboutPartners = [
    {
        id: 0,
        title: 'Бессмертный барак',
        info: 'Текст-описание сайта, из которого понятно, как проект “Имена” с ним связан, как взаимодействует и, главное, из которое следует, что можно найти на сайте “Бессмертного барака” и как вообще с ним быть. К каждому партнеру прилагается кнопка? Если да, то она такая:',
        link: 'https://bessmertnybarak.ru/',
    },
    {
        id: 1,
        title: 'Бессмертный барак',
        info: 'Текст-описание сайта, из которого понятно, как проект “Имена” с ним связан, как взаимодействует и, главное, из которое следует, что можно найти на сайте “Бессмертного барака” и как вообще с ним быть. К каждому партнеру прилагается кнопка? Если да, то она такая:',
        link: '',
    },
    {
        id: 2,
        title: 'Бессмертный барак',
        info: 'Текст-описание сайта, из которого понятно, как проект “Имена” с ним связан, как взаимодействует и, главное, из которое следует, что можно найти на сайте “Бессмертного барака” и как вообще с ним быть. К каждому партнеру прилагается кнопка? Если да, то она такая:',
        link: '',
    },
    {
        id: 3,
        title: 'Бессмертный барак',
        info: 'Текст-описание сайта, из которого понятно, как проект “Имена” с ним связан, как взаимодействует и, главное, из которое следует, что можно найти на сайте “Бессмертного барака” и как вообще с ним быть. К каждому партнеру прилагается кнопка? Если да, то она такая:',
        link: '',
    }
]

export { aboutPartners}