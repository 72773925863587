import React, { useContext, useState, useEffect } from "react";
import Partner from "../components/Partner/Partner";
import "./style.css";
import { aboutPartners } from "../helpers/aboutPartners";
import { AudioContext } from "./../utils/SongContext";

const AboutProject = () => {
    const { setSongIndex, isPlaying, setIsPlaying } = useContext(AudioContext);
    const [activeIndex, setActiveIndex] = useState(0);
    

    useEffect(() => {
        // Устанавливаем начальный индекс песни при загрузке компонента
        setSongIndex(0);
    }, [setSongIndex]);

    const handleArchiveClick = (index) => {
        const playBtn = document.querySelector('.polygon1, .polygon3');
        if (index !== activeIndex) {
            setSongIndex(index);
            setActiveIndex(index);
            if (isPlaying) {
                setIsPlaying(false);
                playBtn.classList.remove('polygon3');
                playBtn.classList.add('polygon1');
            }
        }
    };

    const handlePlayClick = () => {
        const playBtn = document.querySelector('.polygon1, .polygon3');
        if (playBtn) {
            playBtn.classList.toggle('polygon1');
            playBtn.classList.toggle('polygon3');
        }
        if (!isPlaying) {
            setIsPlaying(true);
            window.scrollTo({ top: 0, behavior: 'smooth' });

        }
    };

    return (
        <div className="aboutproject">
            <div className="about_project">
                <ul>
                    <li>О проекте</li>
                    <li>Это подвал, в котором лаконично коротко ясно без лишних или с лишними эмоциями описано рассказано расписано разъяснено, почему проект “Имена” должен был состояться, как он функционирует, как к нему относиться, как к нему стать причастным, как о нем рассказывать, как добавить сайт в закладки, как читать имена.</li>
                    <li>Может даже второй абзац – почему нет? Текст про проект может быть больше чем того мы с вами ожидаем, он может быть многословен, но, конечно, не слишком, он может быть красноречив и даже эмоционален – но, разумеется, не чересчур.</li>
                    <li>Да, это подвал – мне кажется, городить отдельную страницу для текста и контактов может быть не очень разумно, здесь мы можем написать все что мы хотим, и даже оставить какую-то кнопку для обратной связи. Правда, я еще совсем не представляю, как и что, но чувствую, что это могла бы быть кнопка “связаться”, но при этом являться ссылкой характера emailto, которая, после нажатия, приведет на открытое в почтовом клиенте письмо, с введенным почтовым ящиком проекта.</li>
                    <li>И выглядит она вот так:</li>
                    <li>
                        <div className="about_project-btnwrp">
                            <a href="mailto:">
                                <div className="about_project-btn">связаться</div>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="archive">
                <ul className="archive__st">
                    <li>Записи чтений</li>
                    <li>Здесь можно выбрать архивную запись чтений в рамках проекта “ИМЕНА” и послушать. Просто выберите и нажмите слушать – аудио в плеере вверху страницы заменится на выбранное.</li>
                </ul>
                <div className="nd__wrapper">
                    <div className="nd__wrapper-wrap">
                        <ul className="archive__nd">
                            <li className="archive__pl">
                                Чтения имен 2021
                                <div
                                    className={activeIndex === 0 ? "pl-checkbox checked" : "pl-checkbox"}
                                    onClick={() => handleArchiveClick(0)}
                                ></div>
                            </li>
                            <li className="archive__pl">
                                Чтения имен 2022
                                <div
                                    className={activeIndex === 1 ? "pl-checkbox checked" : "pl-checkbox"}
                                    onClick={() => handleArchiveClick(1)}
                                ></div>
                            </li>
                            <li className="archive__pl">
                                Чтения имен 2023
                                <div
                                    className={activeIndex === 2 ? "pl-checkbox checked" : "pl-checkbox"}
                                    onClick={() => handleArchiveClick(2)}
                                ></div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="archive-btnwrp">
                    <div className="archive_project-btn" onClick={handlePlayClick}>слушать</div>
                </div>
            </div>
            <div className="source">
                <div className="source__title">Партнеры и источники</div>
                {aboutPartners.map((partner) => {
                    return (
                        <Partner 
                            title={partner.title} 
                            info={partner.info} 
                            link={partner.link} 
                            key={partner.id} 
                            id={partner.id} 
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default AboutProject;