import "./style.css"

const Partner = ({ title, info, link }) => {
    return (
        <div className="partner">
            <ul>
                <li>{title}</li>
                <li>{info}</li>
            </ul>
            <div className="partner__btn-wrapper">
                <a href={link} target="_blank" rel="noreferrer"><div className="partner__btn">на сайт</div></a>
            </div>

        </div>
    );
}

export default Partner;