import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/style.css";

import ScrollToTop from "./utils/ScrollToTop";
import Header from "./components/Header/Header";
import NameList from "./components/NameList/NameList";
import AboutProject from "./pages/AboutProject";
import Footer from "./components/Footer/Footer";
import { AudioProvider } from "./utils/SongContext"


function App() {
	return (
		<div className="App">
			<AudioProvider>
				<Router>
					<ScrollToTop />
					<Header />
					<Routes>
						<Route path="/" element={<NameList />} />
						<Route path="/aboutproject" element={<AboutProject />} />
					</Routes>
					<Footer />
				</Router>
			</AudioProvider>
		</div>
	);
}

export default App;
