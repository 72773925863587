import { useState, useEffect, useRef } from 'react';
import "./style.css"
import { CSSTransition } from 'react-transition-group';

const Person = ({ id, name, date, info, toggleAll }) => {
    const [show, setShow] = useState(false);
    const nodeRef = useRef(null);

    useEffect(() => {
        setShow(toggleAll);
    }, [toggleAll]);

    const handleToggle = () => {
        setShow(!show);
    };

    return (
        <>
            <div className="person" onClick={handleToggle}>
                <div className="person__num">{id + 1}</div>
                <div className="person__name">
                    <ul>
                        <li>{name}</li>
                        <li>{date}</li>
                    </ul>
                </div>
                <div className="person__checkbox">
                    <button className={`person_checkbox-box ${show ? 'person_checkbox-box-active' : ''}`}></button>
                </div>
            </div>
            <CSSTransition
                in={show}
                timeout={300}
                classNames={{
                    enter: 'slide-enter',
                    enterActive: 'slide-enter-active',
                    exit: 'slide-exit',
                    exitActive: 'slide-exit-active'
                }}
                unmountOnExit
                nodeRef={nodeRef}
            >
                <div className="person__info" ref={nodeRef}>
                    {info}
                </div>
            </CSSTransition>
        </>
    );
}

export default Person;
