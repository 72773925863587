import React, { createContext, useState } from "react";

export const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
    const [songIndex, setSongIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    return (
        <AudioContext.Provider value={{ songIndex, setSongIndex, isPlaying, setIsPlaying }}>
            {children}
        </AudioContext.Provider>
    );
};