import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import 'swiper/css';
import './style.css';
import slide1 from "./../../media/img/slider/image1.jpg"
import slide2 from "./../../media/img/slider/image2.jpg"
import slideMobile from "./../../media/img/slider/imageMobile.jpg"

const SwiperMain = () => {

    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar]}
            spaceBetween={50}
            slidesPerView={1}
            lazyPreloadPrevNext={1}
            navigation={{
                nextEl: '.slider__nav-next',
                prevEl: '.slider__nav-prev',
            }}
            pagination={{
                el: '.swiper-pagination',
                type: 'fraction',
            }}
            scrollbar={{
                el: '.swiper-scrollbar',
                draggable: 'true',
            }}
            initialSlide={0}
            speed={800}
        >
            <SwiperSlide>
                <div className="slider__title">Приглашаем на  чтения имен 2023</div>
                <img src={slide1} alt="" loading='lazy' className="desktop-img" />
                <img src={slideMobile} alt="" loading='lazy' className="mobile-img" />
                <div className="slider__text">
                    Прошлогодний текст анонса приглашения пригласительного вступительного текста о том, как проводятся
                    чтения имен каждый год и каким это будет в предстоящем октябре. Невозможно устоять и не дописать еще пару строчек
                    этого текста сюда, потому что совершенно невообразимо, насколько этот текст здесь будет необходим и насколько подробен.
                    Однако есть уверенность, что если добавить хотя бы еще одну строчку – будет уже чересчур.
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slider__title">Приглашаем на  чтения имен 2024</div>
                <img src={slide1} alt="" loading='lazy' className="desktop-img" />
                <img src={slideMobile} alt="" loading='lazy' className="mobile-img" />
                <div className="slider__text">
                    Текст анонса приглашения пригласительного вступительного текста о том, как проводятся
                    чтения имен каждый год и каким это будет в предстоящем октябре. Невозможно устоять и не дописать еще пару строчек
                    этого текста сюда, потому что совершенно невообразимо, насколько этот текст здесь будет необходим и насколько подробен.
                    Однако есть уверенность, что если добавить хотя бы еще одну строчку – будет уже чересчур.
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slider__title">Приглашаем на  чтения имен 2025</div>
                <img src={slide2} alt="" loading='lazy' className="desktop-img" />
                <img src={slideMobile} alt="" loading='lazy' className="mobile-img" />
                <div className="slider__text">
                    Это уже другой текст анонса приглашения пригласительного вступительного текста о том, как проводятся
                    чтения имен каждый год и каким это будет в предстоящем октябре. Невозможно устоять и не дописать еще пару строчек
                    этого текста сюда, потому что совершенно невообразимо, насколько этот текст здесь будет необходим и насколько подробен.
                    Однако есть уверенность, что если добавить хотя бы еще одну строчку – будет уже чересчур.
                </div>
            </SwiperSlide>
            <div className="swiper-scrollbar"></div>
            <div className="swiper-pagination"></div>
        </Swiper>
    );
}

export default SwiperMain;