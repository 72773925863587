import "./style.css"

const Footer = () => {
    return (
        <div className="footer_wrapper">
            <div className="footer">
                <ul>
                    <li>«ИМЕНА»</li>
                    <li>
                        Это текст-дисклеймер, объявляющий обозначающий назначающий маркирующий юридическую важную информацию,
                        о которой непременно следует знать пользователям всякого ранга статуса и происхождения.
                    </li>
                    <li>2024</li>
                </ul>
            </div>
        </div>
    );
}

export default Footer;