function randomDate() {
    const unknownDateProbability = 0.2;
    if (Math.random() < unknownDateProbability) {
        return 'Даты неизвестны';
    } else {
        const startYear = 1800;
        const endYear = 2000;
        const start = Math.floor(Math.random() * (endYear - startYear + 1)) + startYear;
        const end = Math.floor(Math.random() * (endYear - start + 1)) + start;
        return `${start} - ${end}`;
    }
}

function randomFullName() {
    const firstName = ['Александр', 'Андрей', 'Иван', 'Дмитрий', 'Сергей', 'Михаил', 'Николай', 'Владимир', 'Анатолий', 'Алексей', 'Павел', 'Георгий', 'Виктор'];
    const lastName = ['Иванов', 'Петров', 'Сидоров', 'Козлов', 'Никитин', 'Алексеев', 'Смирнов', 'Павлов', 'Громов', 'Кузнецов', 'Козлов', 'Лебедев', 'Морозов'];
    const middleName = ['Иванович', 'Александрович', 'Сергеевич', 'Петрович', 'Николаевич', 'Михайлович', 'Владимирович', 'Анатольевич', 'Дмитриевич'];
    const randomFirstName = firstName[Math.floor(Math.random() * firstName.length)];
    const randomLastName = lastName[Math.floor(Math.random() * lastName.length)];
    const randomMiddleName = middleName[Math.floor(Math.random() * middleName.length)];
    return `${randomLastName} ${randomFirstName} ${randomMiddleName}`;
}

function randomInfo() {
    const infoList = [
        'Был арестован за участие...".',
        'Подвергался преследованию...',
        'Стал жертвой репрессий...',
        'Был арестован по обвинению...',
        'Подвергался политическим репрессиям...',
        'Стал объектом преследования после того, как...',
        'Был арестован за создание...',
        'Подвергался издевательствам...',
        'Стал жертвой политической репрессии...',
        'Был арестован по подозрению...',
        'Подвергался преследованиям...',
        'Стал объектом репрессий...',
        'Был арестован по подозрению...',
        'Подвергался политическим репрессиям...',
        'Стал жертвой преследований...',
        'Был арестован...',
        'Подвергался преследованиям...',
        'Стал жертвой...',
        'Был арестован за поддержку...',
        'Подвергался издевательствам...',
        'Стал объектом преследования...',
        'Был арестован по обвинению...',
        'Подвергался политическим репрессиям...',
        'Стал жертвой репрессий...',
        'Был арестован за активное участие...',
        'Подвергался издевательствам...',
        'Стал объектом преследования...',
        'Был арестован по подозрению...',
        'Подвергался политическим репрессиям после отказа...',
        'Стал жертвой репрессий после обвинения...',
        'Был арестован за пропаганду опасных идей...',
        'Подвергался издевательствам...',
        'Информация отстутствует',
        
    ];
    return infoList[Math.floor(Math.random() * infoList.length)];
}

const aboutNames = [
    {
        id: 0,
        name: 'Абламский Иван Дмитриевич',
        date: '1886',
        info: 'Репрессирован 16 февраля 1938 г. Тройкой при Управлении НКВД по Московской области 27 февраля 1938 года по обвинению в "принадлежности к нелегальной контрреволюционной эсеровской организации". Реабилитирован: 28 апреля 1956 года',
    },
];

const additionalNames = [];
const targetLength = 155;
while (aboutNames.length < targetLength) {
    additionalNames.push({
        id: aboutNames.length,
        name: randomFullName(),
        date: randomDate(),
        info: randomInfo(),
    });
    aboutNames.push(additionalNames[additionalNames.length - 1]);
}

export { aboutNames };