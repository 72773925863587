import React, { useEffect, useContext, useRef } from "react";
import { AudioContext } from "../../utils/SongContext";
import { NavLink } from "react-router-dom";
import "./style.css";



const Header = () => {
    const { songIndex, isPlaying, setIsPlaying } = useContext(AudioContext);
    const playerRef = useRef(null);
    const playerMenuRef = useRef(null);
    const playBtnRef = useRef(null);
    const audioRef = useRef(null);
    const progressRef = useRef(null);
    const yearRef = useRef(null);

    useEffect(() => {
        const player = playerRef.current;
        const playerMenu = playerMenuRef.current;
        const playBtn = playBtnRef.current;
        const audio = audioRef.current;
        const progress = progressRef.current;
        const year = yearRef.current;

        const songs = ['2021', '2022', '2023'];

        function loadSong(song) {
            year.innerHTML = song;
            audio.src = `https://snakeclub.ru/audio/${song}.mp3`;
        }
        loadSong(songs[songIndex]);

        function playSong() {
            playerMenu.classList.add('play');
            audio.play();
        }

        function pauseSong() {
            playerMenu.classList.remove('play');
            audio.pause();
        }

        if (isPlaying) {
            playSong();
        } else {
            pauseSong();
        }

        const togglePlayPause = () => {
            const isCurrentlyPlaying = playerMenu.classList.contains('play');
            playBtn.classList.toggle('polygon1');
            playBtn.classList.toggle('polygon3');
            if (isCurrentlyPlaying) {
                pauseSong();
            } else {
                playSong();
            }
        };

        const updateProgress = (event) => {
            const { duration, currentTime } = event.srcElement;
            const progressPercent = (currentTime / duration) * 100;
            progress.style.width = `${progressPercent}%`;

            const playerWidth = player.clientWidth;
            Array.from(playerMenu.children).forEach(item => {
                const itemOffset = item.offsetLeft + item.offsetWidth / 2;
                if ((itemOffset / playerWidth) * 100 < progressPercent) {
                    item.classList.add('highlight');
                } else {
                    item.classList.remove('highlight');
                }
            });
        };
        

        audio.addEventListener('ended', () => {
            pauseSong();
            playBtn.classList.remove('polygon3');
            playBtn.classList.add('polygon1');
        });

        playerMenu.addEventListener('click', togglePlayPause);
        audio.addEventListener('timeupdate', updateProgress);
        audio.addEventListener('ended', pauseSong);

        return () => {
            playerMenu.removeEventListener('click', togglePlayPause);
            audio.removeEventListener('timeupdate', updateProgress);
            audio.removeEventListener('ended', pauseSong);
        };
    }, [songIndex, isPlaying, setIsPlaying]);

    return (
        <div className="header">
            <div className="logo">
                <NavLink to="/" className="logo__name">«ИМЕНА»</NavLink>
                <NavLink to="aboutproject" className="logo__about">о проекте</NavLink>
            </div>
            <div ref={playerRef} className="player">
                <audio ref={audioRef} className="player__audio"></audio>
                <div ref={progressRef} className="player__progress"></div>
                <div ref={playerMenuRef} className="player__menu">
                    <p className="player__menu__text">чтения</p>
                    <div ref={playBtnRef} className="polygon1"></div>
                    <p ref={yearRef} className="player__menu__year">{['2021', '2022', '2023'][songIndex]}</p>
                </div>
            </div>
        </div>
    );
}

export default Header;
