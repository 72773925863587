import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { NavLink } from "react-router-dom";
import "./style.css"
import lupe from "./../../media/img/search/lupe.svg"
import decline from "./../../media/img/search/decline.svg"
import Person from "../Person/Person";
import { aboutNames } from "./../../helpers/aboutNames";
import Slider from "../Slider/Slider";

const NameList = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [toggleAll, setToggleAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageRef = useRef(null);
    const scrollNeeded = useRef(false);

    const itemsPerPage = 50;

    const handleSearch = useCallback((event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    }, []);

    const handleToggleAll = useCallback(() => {
        setToggleAll(prevToggleAll => !prevToggleAll);
    }, []);

    const filteredNames = useMemo(() => 
        aboutNames.filter((aboutName) =>
            aboutName.name.toLowerCase().includes(searchTerm.toLowerCase())
        ), [searchTerm]
    );

    const totalPages = Math.ceil(filteredNames.length / itemsPerPage);

    const currentNames = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = currentPage * itemsPerPage;
        return filteredNames.slice(startIndex, endIndex);
    }, [currentPage, filteredNames]);

    useEffect(() => {
        if (pageRef.current && scrollNeeded.current) {
            setTimeout(() => {
                pageRef.current.scrollIntoView({ behavior: "smooth" });
                scrollNeeded.current = false;
            }, 100);
        }
    }, [currentPage, searchTerm]);

    const handlePrevPage = useCallback(() => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
            scrollNeeded.current = true;
        }
    }, [currentPage]);

    const handleNextPage = useCallback(() => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
            scrollNeeded.current = true;
        }
    }, [currentPage, totalPages]);

    const handleClearSearch = useCallback(() => {
        setSearchTerm("");
        setCurrentPage(1);
    }, []);

    return (
        <>
            <Slider />
            <div className="namelist" ref={pageRef}>
                <div className="search">
                    <div className="search__title">Имена</div>
                    <div className="search__field">
                        <form className="search_field-field">
                            <div className="search_icon"><img src={lupe} alt="" /></div>
                            <input
                                type="text"
                                className="search_input"
                                name="search"
                                id="search"
                                placeholder="начните вводить имя"
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <div className={`search_decline ${searchTerm ? 'active' : ''}`} onClick={handleClearSearch}>
                                <img src={decline} alt="" />
                            </div>
                        </form>
                        <button
                            className={`search_field-btn ${toggleAll ? 'search_field-btn-active' : ''}`}
                            onClick={handleToggleAll}
                        >
                            <div className={`search__tumbler2 ${toggleAll ? 'search__tumbler2-active' : ''}`} />
                            биографии
                        </button>
                    </div>
                </div>
                <div className="person__wrapper">
                    {filteredNames.length === 0 ? (
                        <div className="namelist__plug">
                            <span>
                                В нашей базе данных такой записи нет. Проверьте написание имени или свяжитесь с нами на странице 
                                <NavLink to="aboutproject" className="namelist__plug-about"> “О проекте”</NavLink>, чтобы мы добавили новую запись.
                            </span>
                        </div>
                    ) : (
                        currentNames.map((aboutName) => (
                            <Person
                                key={aboutName.id}
                                name={aboutName.name}
                                date={aboutName.date}
                                info={aboutName.info}
                                id={aboutName.id}
                                toggleAll={toggleAll}
                            />
                        ))
                    )}
                </div>
                {filteredNames.length > itemsPerPage && (
                    <div className="person__nav">
                        <button className="person__nav-prev" onClick={handlePrevPage}></button>
                        <div className="person__nav-pagination">
                            <span>{currentPage}/{totalPages}</span>
                        </div>
                        <button className="person__nav-next" onClick={handleNextPage}></button>
                    </div>
                )}
            </div>
        </>
    );
}

export default NameList;